<script>
    
    import Prose from './Prose.svelte';
    import { page } from '$app/stores';

    let className = '';
    export { className as class };

    export let text = $page.data.faq;

</script>


{#if text}
<div
    class="flex flex-col gap-4 max-w-3xl {className}"
    itemscope
    itemtype="https://schema.org/FAQPage"
>
    {@html text}
</div>
{/if}